import autosize from 'autosize';

/**
 *  Autosize any textarea that gets added within the specified rootnode.
 *  This may be optimized further by looking at the individual mutations and by a good root node.
 */
export default function registerAutosizeTextareasListener(rootNode: HTMLElement): () => void {
    const observer = new MutationObserver(function (changes) {
        autosize(rootNode.querySelectorAll('textarea'));
    });

    observer.observe(rootNode, {
        subtree: true,
        childList: true
    });

    return () => observer.disconnect();
}
