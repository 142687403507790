// https://github.com/Microsoft/BotFramework-WebChat#browser-compatibility
import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import registerAutosizeTextareasListener from "./components/bot/webchat/autoSize";
import {
    registerAdaptiveCardUploadButton,
    registerHcaptchaComponent
} from './components/adaptivecards/GlobalActionHandler';
import registerTextAreaCharCounter from './components/bot/webchat/charCounter';

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById('root')
);

registerAdaptiveCardUploadButton();
registerHcaptchaComponent();
registerTextAreaCharCounter(document.body);
registerAutosizeTextareasListener(document.body);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
