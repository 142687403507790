export class KumulEvent {
    constructor(
        public name: string = '',
        public welcomeMessage: string = '',
        public conclusionText: string = '',
        public active: boolean = false
    ) {

    }
}
