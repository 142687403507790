import * as Adaptive from "adaptivecards";
import { sendBoxTextBoxUploadButtonSelector } from '../../constants';

export function registerAdaptiveCardUploadButton() {
    class UploadButtonClickAction extends Adaptive.Action {

        getJsonTypeName(): string {
            return "Action.UploadButtonClick";
        }

        execute() {
             (document.querySelector(sendBoxTextBoxUploadButtonSelector) as HTMLElement).click();
        }
    }

    Adaptive.AdaptiveCard.actionTypeRegistry.registerType("Action.UploadButtonClick", () => { return new UploadButtonClickAction(); });
}

export function registerHcaptchaComponent() {
    class HcaptchaComponent extends Adaptive.Input{

        protected _hcaptchaValue : string = "";
        protected _containerElement : HTMLElement | null = null;
        protected _siteKey: string = "";

        getJsonTypeName(): string {
            return "domcura.captcha";
        }

        get value(): string {
            return this._hcaptchaValue;
        }

        parse(json: any, errors?: Array<any>) {
            super.parse(json, errors);

            this._siteKey = json["siteKey"];
        }

        protected internalRender(): HTMLElement {
            if (this._containerElement) {
                return this._containerElement;
            }

            const element = document.createElement('div');
            element.className = 'h-captcha';

            // I found no way to set a callback using JS in the documentation, so we'll create a unique global function.
            // This might prevent this object from being garbage collected, but that shouldn't be THAT bad.
            const callbackName = "hcaptcha_callback_" + Math.random();
            window[callbackName] = (code) => {
                console.log("Got Captcha result", code);
                this._hcaptchaValue = code;
            };
            element.setAttribute('data-callback', callbackName);
            element.setAttribute('data-sitekey', this._siteKey);
            this._containerElement = element;
            (window["hcaptcha"]).render(element);

            return element;
        }
    }
    Adaptive.AdaptiveCard.elementTypeRegistry.registerType("domcura.captcha", () => { return new HcaptchaComponent(); });
}
