import React from 'react';
import './Bot.scss';
import { WebChatComponent } from './webchat/WebChat';
import { FooterComponent } from '../footer/Footer';
import { directLine } from '../../services/DirectLine';

export class BotPage extends React.Component {

  private webChatComponent: any;
  private footerComponent: any;

  constructor(props: any) {
    super(props);
    console.debug('BotPage.ctor()');

    this.webChatComponent = React.createRef();
    this.footerComponent = React.createRef();
  }

  handleReport = (_: any) => {
    console.debug('BotPage.handleReport()');
    directLine.requestProtocol();
  }

  handleReset = (_: any) => {
    console.debug('BotPage.handleReset()');
    directLine.requestEndOfConversation();
  }

  handleBack = (_: any) => {
    console.debug('BotPage.handleBack()');
    this.webChatComponent.current.store.dispatch({
      type: 'WEB_CHAT/SEND_MESSAGE',
      payload: { text: 'zurück' }
    });
  }

  handleContact = (_: any) => {
    console.debug('BotPage.handleContact()');
    directLine.requestEmployeeChat();
  }

  handleNext = ({ stepCount, stepIndex }) => {
    console.debug('BotPage.handleNext()');
    this.footerComponent.current.update(stepCount, stepIndex || 0);
  }

  render() {
    console.debug('BotPage.render()');

    return (
      <div className="bot-container">
        <WebChatComponent ref={this.webChatComponent}
          onNextStep={this.handleNext} />
        <FooterComponent ref={this.footerComponent}
          onReport={this.handleReport}
          onContact={this.handleContact}
          onReset={this.handleReset}
          onBack={this.handleBack}
        />
      </div>
    );
  }
}
