import React from "react";
import "./UpsertModal.scss";
import { Modal, Button, Form } from 'react-bootstrap';
import { KumulEvent } from "../../models/KumulEvent";

export class UpsertModal extends React.Component<{
    onClose: (event: KumulEvent | null) => void
}, {
    open: boolean,
    isUpdateMode: boolean,
    event: KumulEvent
}> {

    constructor(props: any) {
      super(props);
      console.debug('UpsertModal.ctor()');

      this.state = { 
          open: false,
          isUpdateMode: false,
          event: new KumulEvent()
      };
    }

    private handleNameChange = (e) => {
        console.debug(`UpsertModal.handleNameChange(${e.target.value})`);
        let event = this.state.event;
        if (!event) {
            return;
        }
        event.name = e.target.value;
        this.setState({ event });
    };

    private handleWelcomeMessageChange = (e) => {
        console.debug(`UpsertModal.handleTextChange(${e.target.value})`);
        let event = this.state.event;
        if (!event) {
            return;
        }
        event.welcomeMessage = e.target.value;
        this.setState({ event });
    };

    private handleConclusionTextChange = (e) => {
        console.debug(`UpsertModal.handleConclusionTextChange(${e.target.value})`);
        let event = this.state.event;
        if (!event) {
            return;
        }
        event.conclusionText = e.target.value;
        this.setState({ event });
    };

    private handleSave = () => {
        console.debug('UpsertModal.handleSave()');
        this.close(this.state.event);
    };
    
    private handleSaveAndActivate = () => {
        console.debug('UpsertModal.handleSaveAndActivate()');
        let event = this.state.event;
        event.active = true;
        this.close(event);
    };
    
    private handleCancel = () => {
        console.debug('UpsertModal.handleCancel()');
        this.close(null);
    };

    public open(event: KumulEvent | null = null) {
        console.debug(`UpsertModal.open(${event})`);
        this.setState({ 
            open: true,
            isUpdateMode: event !== null,
            event: event === null ? new KumulEvent() : event
        });
    }

    private close(event: KumulEvent | null) {
        console.debug('UpsertModal.close()');
        this.props.onClose(event);
        this.setState({ open: false });
    }

    render() {
        console.debug('UpsertModal.render()');
        if (!this.state) {
            return null;
        }

        return (
            <>
            <Modal show={this.state.open} onHide={this.handleCancel} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Kumul Ereignis {this.state.isUpdateMode ? 'bearbeiten' : 'erstellen'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Bitte geben Sie einen eindeutigen Namen für das Ereignis an"
                                value={this.state.event.name} onChange={this.handleNameChange} disabled={this.state.isUpdateMode}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Willkommensnachricht</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Bitte geben Sie eine aussagekräftige Nachricht an" 
                                value={this.state.event.welcomeMessage} onChange={this.handleWelcomeMessageChange} 
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Zusammenfassung</Form.Label>
                            <Form.Control as="textarea" rows={3} placeholder="Bitte geben Sie eine aussagekräftige Zusammenfassung an" 
                                value={this.state.event.conclusionText} onChange={this.handleConclusionTextChange} 
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" onClick={this.handleSave} disabled={!this.state.event || !this.state.event.name || !this.state.event.welcomeMessage || !this.state.event.conclusionText}>
                    Speichern
                </Button>
                <Button variant="primary" onClick={this.handleSaveAndActivate} disabled={!this.state.event || !this.state.event.name || !this.state.event.welcomeMessage || !this.state.event.conclusionText}>
                    Speichern & aktivieren
                </Button>
                <Button variant="secondary" onClick={this.handleCancel}>
                    Abbrechen
                </Button>
                </Modal.Footer>
            </Modal>
            </>
        );
    }

}
