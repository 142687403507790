export default function registerTextAreaCharCounter(rootNode: HTMLElement): () => void {
    const observer = new MutationObserver(function (changes) {
        const textAreas : NodeListOf<HTMLTextAreaElement>= rootNode.querySelectorAll(".ac-textInput.ac-multiline[maxlength]:not(.has-counter)");
        for (let i = 0; i < textAreas.length; i++) {
            const textArea = textAreas[i];
            const charCounter = document.createElement('span');
            let triggerUpdate = function () {
                charCounter.textContent = textArea.value.length + ' / ' + textArea.maxLength + " Zeichen";
            };
            textArea.addEventListener('input', triggerUpdate);
            textArea.parentElement?.insertAdjacentElement('afterend', charCounter);
            textArea.classList.add('has-counter');
            triggerUpdate();
        }
    });

    observer.observe(rootNode, {
        subtree: true,
        childList: true
    });

    return () => observer.disconnect();
}
