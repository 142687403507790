import { DirectLine } from "botframework-directlinejs";
import { v4 as uuid } from 'uuid';
import { Observable } from "rxjs/Observable";
import { createDirectLine } from "botframework-webchat";
import { backend } from "./Backend";
import { DirectLineToken } from "../models/DirectLineToken";

const tokenRefreshInterval = 5 * 60 * 1000; // 5 minutes

class DirectLineService {

  public userId: string = `dl_${uuid()}`;
  public botConnection!: DirectLine;
  public token!: DirectLineToken;

  constructor() {
    console.debug('DirectLineService.ctor()');

    setInterval(async () => await this.refreshToken(), tokenRefreshInterval);
  }

  public async connect() {
    console.debug('DirectLineService.connect()');

    const token = await this.getToken();

    console.debug('DirectLineService.connect(): initializing direct line connection');
    this.botConnection = createDirectLine({ token });
  }

  public startConversation() {
    console.debug('DirectLineService.startConversation()');
    this.postEventActivity('startConversation')
      .subscribe(
        (_: any) => { },
        (error: any) => console.error(error)
      );
  }

  public endConversation(): Observable<any> {
    console.debug('DirectLineService.resetConversation()');
    return this.postEventActivity('endOfConversation');
  }

  sendMessage(text: string) {
    console.debug('DirectLineService.sendMessage()');
    return this.postMessageActivity(text);
  }

  public requestProtocol() {
    console.debug('DirectLineService.requestProtocol()');
    this.postEventActivity('sendProtocol')
      .subscribe(
        _ => { },
        error => console.error(error)
      );
  }
  public requestEmployeeChat() {
    console.debug('DirectLineService.requestEmployeeChat()');
    this.postEventActivity('employeeChat')
        .subscribe(
            _ => { },
            error => console.error(error)
        );
  }

  public requestEndOfConversation() {
    console.debug('DirectLineService.requestRestart()');
    this.postEventActivity('endOfConversation')
      .subscribe(
        _ => { },
        error => console.error(error)
      );
  }

  public dispose() {
    console.debug('DirectLineService.dispose()');

    this.botConnection.end();
    delete this.botConnection;
  }

  private postEventActivity(name: string): Observable<any> {
    console.debug('DirectLineService.postActivity()');

    return this.botConnection.postActivity({
      from: {
        id: this.userId
      },
      name: name,
      type: 'event',
      value: '',
      channelData: {
        "personId": this.userId,
        "environment": window.location.host
      }
    });
  }

  private postMessageActivity(text: string): Observable<any> {
    console.debug('DirectLineService.postMessageActivity()');

    return this.botConnection.postActivity({
      from: {
        id: this.userId
      },
      type: 'message',
      text: text,
      channelData: {
        "personId": this.userId,
        "environment": window.location.host
      }
    });
  }

  private async getToken(): Promise<string> {
    console.debug('DirectLineService.getToken()');

    this.token = await backend.getDirectLineToken(this.userId);

    return this.token.token;
  }

  public async refreshToken(): Promise<void> {
    if (!this.token || !this.token.token) {
      return;
    }
    console.debug('DirectLineService.refreshToken()');

    const json = await backend.refreshDirectLineToken(this.token.token);

    this.token = json;

    this.botConnection.reconnect({
      conversationId: this.token.conversationId,
      token: this.token.token
    });
  }
}

export const directLine = new DirectLineService();
