import { environment } from "../environment";
import { DirectLineToken } from "../models/DirectLineToken";
import { KumulEvent } from "../models/KumulEvent";

class BackendService {

    public async getDirectLineToken(userId: string): Promise<DirectLineToken> {
        console.debug('BackendService.getToken()', userId);

        const response = await fetch(`${environment.serverUrl}/directline/auth/token`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: `"${userId}"`
        });

        return await response.json();
    }

    public async refreshDirectLineToken(token: string): Promise<DirectLineToken> {
      console.debug('BackendService.refreshToken()');
  
      const response = await fetch(`${environment.serverUrl}/directline/auth/token/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: `"${token}"`
      });
      
      return await response.json();
    }

    public async getKumulEvents(): Promise<KumulEvent[]> {
        console.debug('BackendService.getKumulEvents()');
  
        const response = await fetch(`${environment.serverUrl}/api/kumul`);
        
        return await response.json();
    }

    public async upsertKumulEvent(event: KumulEvent): Promise<void> {
        console.debug('BackendService.updateKumulEvent()');
  
        await fetch(`${environment.serverUrl}/api/kumul`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8'
            },
            body: JSON.stringify(event)
        });
    }

    public async deleteKumulEvent(name: string): Promise<void> {
        console.debug('BackendService.deleteKumulEvent()');
  
        await fetch(`${environment.serverUrl}/api/kumul?name=${name}`, { 
            method: 'DELETE' 
        });
    }
}

export const backend = new BackendService();
