import React from "react";
import './Footer.scss';
import { ProgressBar } from "react-bootstrap";

export class FooterComponent extends React.Component<{
  onReport: ((event?: any) => void),
  onContact: ((event?: any) => void),
  onReset: ((event?: any) => void),
  onBack: ((event?: any) => void)
}, {
  stepCount: number,
  stepIndex: number
}> {

  state = {
    stepCount: 0,
    stepIndex: 0
  }

  constructor(props: any) {
    super(props);
    console.debug('FooterComponent.ctor()');
  }

  public update(stepCount: number, stepIndex: number) {
    console.debug('FooterComponent.update()');
    this.setState({
      stepCount: stepCount,
      stepIndex: stepIndex
    });
  }

  render() {
    console.debug('FooterComponent.render()');

    return (
      <div>
        <div className="footer-container">

          <div className="progress-bar-container">
            <ProgressBar now={this.state.stepIndex / this.state.stepCount * 100} label={`${this.state.stepIndex}/${this.state.stepCount}`} />
          </div>

          <div className="menu">
            {<div className="menu-content">
              <div className={this.state.stepIndex > 1 ? 'list-inline-item' : 'list-inline-item disabled'} onClick={this.props.onReport} >
                <i className="fa fa-envelope icon"></i>
                <br />
                <p>Chatverlauf via E-Mail senden</p>
              </div>
              <div className={this.state.stepIndex > 1 ? 'list-inline-item' : 'list-inline-item disabled'} onClick={this.props.onContact} >
                <i className="fa fa-comments icon"></i>
                <br />
                <p>Mit Mitarbeiter chatten</p>
              </div>
              <div className={this.state.stepIndex > 1 ? 'list-inline-item' : 'list-inline-item disabled'} onClick={this.props.onBack} >
                <i className="fa fa-arrow-left icon"></i>
                <br />
                <p>Letzten Schritt wiederholen</p>
              </div>
              <div className={this.state.stepIndex > 1 ? 'list-inline-item' : 'list-inline-item disabled'} onClick={this.props.onReset} >
                <i className="fa fa-repeat icon"></i>
                <br />
                <p>Schadenassistent erneut starten</p>
              </div>
            </div>}
          </div>

        </div>
      </div>
    );
  }
}
