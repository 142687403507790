import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BotPage } from './components/bot/Bot';
import { KumulPage } from './components/kumul/Kumul';
import { HeaderComponent } from './components/header/Header';

class App extends React.Component {
  render() {
    return (
      <>
        <HeaderComponent />
        <Switch>
          <Route exact path="/" component={BotPage} />
          <Route path="/kumul" component={KumulPage} />
        </Switch>
      </>
    );
  }
}

export default App;
