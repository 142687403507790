import React from "react";
import './Header.scss';

export class HeaderComponent extends React.Component {
    render() {
        return (
            <>
                <div className="header-container">
                    <div className="logo-container">
                        <div className="inner-container">
                            <div className="logo">
                                <a href="./">
                                    <img src="./assets/logo_dc.png" alt="DOMCURA AG"></img>
                                </a>
                            </div>
                            {/* <div id="socials">                      
                                <a href="https://twitter.com/domcura_ag" target="_blank" id="twitter" title="Twitter"></a>
                                <a href="https://www.facebook.com/domcura.ag/" target="_blank" id="facebook" title="facebook"></a>
                                <a href="http://www.youtube.com/domcuramedia" target="_blank" id="youtube" title="YouTube"></a>
                                <a href="http://www.xing.com/companies/domcuraag" target="_blank" id="xing" title="Xing"></a>
                                <a href="https://www.linkedin.com/company/domcura-ag/" target="_blank" id="linkedin" title="LinkedIn"></a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
