import { MsalAuthProvider, LoginType } from 'react-aad-msal';

export const authProvider = new MsalAuthProvider(
    {
        auth: {
            authority: 'https://login.microsoftonline.com/common',
            clientId: '1bc0017e-3dc5-4d69-b60b-61a8f6e4cbc8',
            redirectUri: window.location.origin
        },
        cache: {
            cacheLocation: "localStorage",
            storeAuthStateInCookie: true
        }
    }, 
    {
        scopes: [
            'User.Read'
        ]
    },
    {
        loginType: LoginType.Redirect,
        // tokenRefreshUri: window.location.origin + '/auth.html'
    }
);
