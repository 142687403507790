import { createStyleSet } from "botframework-webchat";
import { environment } from "../../../environment";
import { green, white, greyLight, bubbleBorderRadius, greyLighter, bubbleNubSize, greyDark, grey, red } from "../../../constants";

// const PADDING_REGULAR = 10;

// https://github.com/microsoft/BotFramework-WebChat/blob/master/packages/component/src/Styles/defaultStyleOptions.js
// https://github.com/microsoft/BotFramework-WebChat/blob/master/packages/component/src/Styles/createStyleSet.js
// https://github.com/microsoft/BotFramework-WebChat/tree/master/samples/02.branding-styling-and-customization/a.branding-web-chat
let styleOptions = {
  // Color and paddings
  accent: green,
  backgroundColor: white,
  cardEmphasisBackgroundColor: '#F0F0F0',
  // paddingRegular: PADDING_REGULAR,
  // paddingWide: PADDING_REGULAR * 2,
  subtle: greyLight,

  // Word break
  // messageActivityWordBreak: 'break-word', // 'normal' || 'break-all' || 'break-word' || 'keep-all'

  // Fonts
  // fontSizeSmall: '100%',
  // monospaceFont: fontFamily(['Consolas', 'Courier New', 'monospace']),
  primaryFont: `'Open Sans', sans-serif`,

  // Avatar
  avatarBorderRadius: '0',
  avatarSize: 50,
  botAvatarBackgroundColor: white, // defaults to accent color
  botAvatarImage: './assets/bot_head_0.png',
  // botAvatarInitials: 'BO',
  userAvatarBackgroundColor: white, // defaults to accent color
  userAvatarImage: './assets/user.png',
  // userAvatarInitials: 'US',

  // Bubble
  bubbleBackground: green,
  bubbleBorderColor: green,
  bubbleBorderRadius: bubbleBorderRadius,
  // bubbleBorderStyle: 'solid',
  // bubbleBorderWidth: 1,
  bubbleFromUserBackground: greyLighter,
  bubbleFromUserBorderColor: greyLighter,
  bubbleFromUserBorderRadius: bubbleBorderRadius,
  // bubbleFromUserBorderStyle: 'solid',
  // bubbleFromUserBorderWidth: 1,
  // bubbleFromUserNubOffset: 'bottom',
  bubbleFromUserNubSize: bubbleNubSize,
  bubbleFromUserTextColor: greyDark,
  // bubbleImageHeight: 240,
  bubbleMaxWidth: 980, // screen width = 600px
  // bubbleMinHeight: 40,
  // bubbleMinWidth: 250, // min screen width = 300px, Edge requires 372px (https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/13621468/)
  bubbleNubOffset: 'top',
  bubbleNubSize: bubbleNubSize,
  bubbleTextColor: greyDark,

  // Markdown
  markdownRespectCRLF: true,

  // Rich Cards
  // richCardWrapTitle: false, // Applies to subtitles as well

  // Root
  rootHeight: '100%',
  rootWidth: '100%',

  // Scroll to end button
  // hideScrollToEndButton: false,

  // Send box
  // hideSendBox: false,
  // hideUploadButton: false,
  // microphoneButtonColorOnDictate: '#F33',
  // sendBoxBackground: 'White',
  sendBoxButtonColor: greyDark, // defaults to subtle
  // sendBoxButtonColorOnDisabled: '#CCC',
  sendBoxButtonColorOnFocus: greyDark,
  sendBoxButtonColorOnHover: grey,
  // sendBoxDisabledTextColor: undefined, // defaults to subtle
  // sendBoxHeight: 40,
  // sendBoxMaxHeight: 200,
  sendBoxTextColor: greyDark,
  // sendBoxBorderBottom: '',
  // sendBoxBorderLeft: '',
  // sendBoxBorderRight: '',
  // sendBoxBorderTop: 'solid 1px #E6E6E6',
  sendBoxPlaceholderColor: greyDark, // defaults to subtle
  // sendBoxTextWrap: false,

  // Visually show spoken text
  // showSpokenText: false,

  // Suggested actions
  // suggestedActionBackground: 'White',
  // suggestedActionBorder: undefined, // split into 3, null
  // suggestedActionBorderColor: undefined, // defaults to accent
  // suggestedActionBorderRadius: 0,
  // suggestedActionBorderStyle: 'solid',
  // suggestedActionBorderWidth: 2,
  // suggestedActionDisabledBackground: undefined, // defaults to suggestedActionBackground
  // suggestedActionDisabledBorder: null,
  // suggestedActionDisabledBorderColor: '#E6E6E6',
  // suggestedActionDisabledBorderStyle: 'solid',
  // suggestedActionDisabledBorderWidth: 2,
  // suggestedActionDisabledTextColor: undefined, // defaults to subtle
  // suggestedActionHeight: 40,
  // suggestedActionImageHeight: 20,
  // suggestedActionLayout: 'carousel', // either "carousel" or "stacked"
  // suggestedActionTextColor: null,

  // Timestamp
  // groupTimestamp: true,
  // sendTimeout: 20000,
  // sendTimeoutForAttachments: 120000,
  // timestampColor: undefined, // defaults to subtle
  // timestampFormat: 'relative', // 'absolute'

  // Transcript overlay buttons (e.g. carousel and suggested action flippers, scroll to bottom, etc.)
  transcriptOverlayButtonBackground: grey,
  transcriptOverlayButtonBackgroundOnFocus: greyLight,
  transcriptOverlayButtonBackgroundOnHover: greyLight,
  // transcriptOverlayButtonColor: 'White',
  // transcriptOverlayButtonColorOnFocus: undefined, // defaults to transcriptOverlayButtonColor
  // transcriptOverlayButtonColorOnHover: undefined, // defaults to transcriptOverlayButtonColor

  // Video
  // videoHeight: 270, // based on bubbleMaxWidth, 480 / 16 * 9 = 270

  // Connectivity UI
  // connectivityIconPadding: PADDING_REGULAR * 1.2,
  // connectivityMarginLeftRight: PADDING_REGULAR * 1.4,
  // connectivityMarginTopBottom: PADDING_REGULAR * 0.8,
  // connectivityTextSize: '75%',
  failedConnectivity: red,
  // slowConnectivity: '#EAA300',
  // notificationText: '#5E5E5E',
  // slowConnectionAfter: 15000,

  typingAnimationBackgroundImage: `url(${environment.serverUrl}/assets/typing-animation.gif)`,
  // typingAnimationDuration: 5000,
  typingAnimationHeight: 36,
  // typingAnimationWidth: 64,

  // spinnerAnimationBackgroundImage: null,
  // spinnerAnimationHeight: 16,
  // spinnerAnimationWidth: 16,
  // spinnerAnimationPadding: 12,

  // enableUploadThumbnail: true,
  // uploadThumbnailContentType: 'image/jpeg',
  // uploadThumbnailHeight: 360,
  // uploadThumbnailQuality: 0.6,
  // uploadThumbnailWidth: 720,

  // deprecated; will be removed on or after 2021-02-01
  // spinnerAnimationPaddingRight: undefined,

  // Toast UI

  // New debounce timeout value only affect new notifications.
  // notificationDebounceTimeout: 400,

  // hideToaster: false,
  // toasterHeight: 32,
  // toasterMaxHeight: 32 * 5,
  // toasterSingularMaxHeight: 50,
  // toastFontSize: '87.5%',
  // toastIconWidth: 36,
  // toastSeparatorColor: '#E8EAEC',
  // toastTextPadding: 6,

  // toastErrorBackgroundColor: '#FDE7E9',
  // toastErrorColor: '#A80000',
  // toastInfoBackgroundColor: '#CEF1FF',
  // toastInfoColor: '#105E7D',
  // toastSuccessBackgroundColor: '#DFF6DD',
  // toastSuccessColor: '#107C10',
  // toastWarnBackgroundColor: '#FFF4CE',
  // toastWarnColor: '#3B3A39'
};

export function createStyles(rootWidth: string | null = null, rootHeight: string | null = null): any {

  if (rootWidth) {
    styleOptions.rootWidth = rootWidth;
  }
  if (rootHeight) {
    styleOptions.rootHeight = rootHeight;
  }

  // https://github.com/microsoft/BotFramework-WebChat/tree/master/samples/02.branding-styling-and-customization/a.branding-web-chat#my-required-changes-are-not-all-specified-in-defaultstyleoptionsjs-what-do-i-do-now
  // https://github.com/microsoft/BotFramework-WebChat/tree/master/samples/02.branding-styling-and-customization/b.idiosyncratic-manual-styles
  let styleSet = createStyleSet(styleOptions);

  if (rootWidth) {
    styleSet.root.maxWidth = rootWidth;
  }
  if (rootHeight) {
    styleSet.root.maxHeight = rootHeight;
  }
  
  // https://github.com/microsoft/BotFramework-WebChat/blob/df4fb972424a36ae5d24ebec249bce00644964e8/packages/component/src/Styles/StyleSet/SendBoxTextBox.js#L1
  styleSet.sendBoxTextBox['& > input'] = {
    ...styleSet.sendBoxTextBox['& > input'],
    fontWeight: 'bold'
  };

  // styleSet.bubble['& > .webchat__bubble__content'] = {
  //   ...styleSet.bubble['& > .webchat__bubble__content'],
  //   minWidth: '200px'
  // };
  
  // https://github.com/microsoft/BotFramework-WebChat/blob/master/packages/bundle/src/adaptiveCards/Styles/StyleSet/AdaptiveCardRenderer.js
  styleSet.adaptiveCardRenderer['& .ac-container'] = {
    ...styleSet.adaptiveCardRenderer['& .ac-container'],
    background: white
  };
  styleSet.adaptiveCardRenderer['& .ac-textInput'] = {
    ...styleSet.adaptiveCardRenderer['& .ac-textInput'],
    padding: '10px'
  };
  styleSet.adaptiveCardRenderer['& .ac-dateInput'] = {
    ...styleSet.adaptiveCardRenderer['& .ac-dateInput'],
    padding: '10px'
  };
  styleSet.adaptiveCardRenderer['& .ac-pushButton'] = {
    ...styleSet.adaptiveCardRenderer['& .ac-pushButton'],
    background: greyLight,
    color: white
  };

  return styleSet;
}
