export const white = '#fff';
export const green = '#b3dc1f';
export const greyLighter = '#d8d8d8';
export const greyLight = '#9b9b9b';
export const grey = '#7f7f7f';
export const greyDark = '#4a4a4a';
export const red = '#dc3545';
export const redRgb = 'rgb(220, 53, 69)';
// export const blueDark = ''; // primary button clicked background
// export const blueLight = ''; // primary button clicked border

export const bubbleNubSize = 10;
export const bubbleBorderRadius = 8;

export const sendBoxTextBoxSelector = 'div.main > form > input';
export const sendBoxTextBoxPlaceholder = 'Haben Sie eine Frage?';
export const sendBoxTextBoxUploadAttachmentPlaceholder = 'Auf die Klammer klicken, um Anhänge hochzuladen.';
export const sendBoxTextBoxUploadButtonSelector = 'div.main > div > button';

export const adaptiveCardButtonClass = 'ac-pushButton';
