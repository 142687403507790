import React from "react";
import ReactWebChat, { createStore } from 'botframework-webchat';
import { createStyles } from './WebChat.css';
import { directLine } from "../../../services/DirectLine";
import MarkdownIt from 'markdown-it';
import './WebChat.scss';
import { HelpComponent } from "../help/Help";
import { sendBoxTextBoxPlaceholder, sendBoxTextBoxUploadAttachmentPlaceholder, sendBoxTextBoxSelector, adaptiveCardButtonClass, sendBoxTextBoxUploadButtonSelector } from "../../../constants";

export class WebChatComponent extends React.Component<{
  onNextStep: (event?: any) => void
}, {
  styleSet: any,
  helpText: string | null
}, any> {

  state = {
    styleSet: createStyles(),
    helpText: null
  };

  public store: any;
  private md: MarkdownIt = new MarkdownIt({
    html: true,
    breaks: true,
    linkify: true
  });

  private helpComponent: any;

  constructor(props: any) {
    super(props);
    console.debug('WebChatComponent.ctor()');

    this.helpComponent = React.createRef();

    this.connect();
  }

  public reconnect() {
    console.debug('WebChatComponent.reconnect()');

    directLine.endConversation()
      .subscribe(
        (_: any) => {
          directLine.dispose();
          this.store = null;
          this.forceUpdate();
          this.connect();
        },
        (error: any) => console.error(error)
      );
  }

  private connect() {
    console.debug('WebChatComponent.connect()');

    this.store = createStore({}, (_: any) => (next: any) => (action: any) => {
      console.debug(`WebChatComponent.store: ${action.type}`);
      if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
        // connection established successfully
        // modify the placeholder of the input text box and focus it
        this.initSendBoxTextBox(sendBoxTextBoxPlaceholder);
      }
      if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
        if (action.payload.activity.from.role === 'bot') {
          // received incoming activity from bot
          if (action.payload.activity.type === 'message') {
            // received incoming message activity from bot
            if (action.payload.activity.channelData) {
              this.propagateChannelData(action.payload.activity.channelData);
            }
            else {
              // clear old helpText and propagate it to help component
              // do this only in case the helpText was not cleared by the previous step already
              this.initSendBoxTextBox(sendBoxTextBoxPlaceholder);
              this.clearHelp();
              this.stopHighlightUploadButtonAnimation();
            }
          }
          else if (action.payload.activity.type === 'endOfConversation') {
            // received incoming endOfConversation activity from bot
            console.debug('WebChatComponent.store: incoming endOfConversation activity from bot');
            this.reconnect();
          }
        }
      }
      if (action.type === 'DIRECT_LINE/POST_ACTIVITY') {
        // sending adaptive card back to bot
        // hide previous adaptive card buttons
        this.hideAdaptiveCardButtons();
      }
      return next(action);
    });

    directLine.connect()
      .then(_ => {
        this.forceUpdate();
        directLine.startConversation();
      })
      .catch(error => console.error(error));
  }

  private initSendBoxTextBox(placeholder: string) {
    console.debug(`WebChatComponent.initSendBoxTextBox(${placeholder})`);

    let sendBoxTextBox = document.querySelector(sendBoxTextBoxSelector) as any;
    if (!sendBoxTextBox) {
      return;
    }

    sendBoxTextBox.focus();

    if (sendBoxTextBox.placeholder === placeholder) {
      return;
    }
    sendBoxTextBox.placeholder = placeholder;
  }

  private hideAdaptiveCardButtons() {
    console.debug(`WebChatComponent.hideAdaptiveCardButtons()`);

    const adaptiveCardButtons = document.getElementsByClassName(adaptiveCardButtonClass);
    if (!adaptiveCardButtons) {
      return;
    }
    for (let n = 0; n < adaptiveCardButtons.length; n++) {
      let button = adaptiveCardButtons.item(n) as any;
      if (!button) {
        continue;
      }
      button.style.display = 'none';
    }
  }

  private propagateChannelData(channelData: any) {
    console.debug(`WebChatComponent.propagateChannelData()`);
    // propagate new helpText to help component
    this.updateHelp(channelData.helpText);
    // propagate new progress state to component
    this.updateProgress(channelData.stepCount, channelData.stepIndex);
    // highlight upload button if backend expects attachments
    this.toggleHighlightUploadButtonAnimation(channelData.expectingAttachments === true);
  }

  private updateHelp(helpText: string | null) {
    console.debug(`WebChatComponent.updateHelp()`);
    if (this.state.helpText === helpText) {
      // no update required, prevent re-rendering
      console.debug(`WebChatComponent.updateHelp(): already up to date`);
      return;
    }
    this.setState({ helpText: helpText });
  }

  private clearHelp() {
    console.debug(`WebChatComponent.clearHelp()`);
    this.updateHelp(null);
  }

  private updateProgress(stepCount: number, stepIndex: number) {
    console.debug(`WebChatComponent.updateProgress()`);
    // notify parent component about the changes
    this.props.onNextStep({ stepCount, stepIndex });
  }

  private toggleHighlightUploadButtonAnimation(active: boolean) {
    console.debug(`WebChatComponent.toggleHighlightUploadButtonAnimation(${active})`);

    if (active) {
      this.startHighlightUploadButtonAnimation();
      this.initSendBoxTextBox(sendBoxTextBoxUploadAttachmentPlaceholder);
    }
    else {
      this.stopHighlightUploadButtonAnimation();
      this.initSendBoxTextBox(sendBoxTextBoxPlaceholder);
    }
  }

  private startHighlightUploadButtonAnimation() {
    console.debug(`WebChatComponent.startHighlightUploadButtonAnimation()`);

    let uploadButton = document.querySelector(sendBoxTextBoxUploadButtonSelector) as HTMLElement;
    uploadButton.classList.add('highlight');
  }

  private stopHighlightUploadButtonAnimation() {
      let uploadButton = document.querySelector(sendBoxTextBoxUploadButtonSelector) as HTMLElement;
      uploadButton.classList.remove('highlight');
  }


  private renderMarkdown = (markdown: string, options: { markdownRespectCRLF: boolean }): string => {
    console.debug(`WebChatComponent.renderMarkdown(${markdown}, ${options})`);
    return this.md.render(markdown);
  }

  private handleClose = () => {
    console.debug('WebChatComponent.handleClose()');

    window.removeEventListener('beforeunload', this.handleClose);

    directLine.endConversation()
      .subscribe(
        () => {
          console.debug('WebChatComponent.handleClose(): succeeded');
          directLine.dispose();
        },
        error => console.error('WebChatComponent.handleClose()', error)
      );
  }

  private handleResize = () => {
    console.debug('WebChatComponent.handleResize()');

    const width = document.body.clientWidth < 980
      ? `${document.body.clientWidth}px`
      : `100%`;

    let height: string | null = null;
    const header = document.getElementsByClassName('header-container')[0];
    const shadow = document.getElementsByClassName('shadow-top')[0];
    const footer = document.getElementsByClassName('footer-container')[0];
    if (header && shadow && footer) {
      height = `${document.body.clientHeight - header.clientHeight - shadow.clientHeight - footer.clientHeight}px`;
    }

    // Note: This will cause all card components to re-render, which can lead to inconsistencies.
    this.setState({ styleSet: createStyles(width, height) });
  }

  componentDidMount() {
    console.debug('WebChat.componentDidMount()');

    window.addEventListener('beforeunload', this.handleClose);
    window.addEventListener('resize', this.handleResize);
    // Sometimes, the bots initializes really slowly, so we try to handle resize at increasing intervals.
    setTimeout(this.handleResize, 500);
    setTimeout(this.handleResize, 2000);
    setTimeout(this.handleResize, 5000);
    setTimeout(this.handleResize, 12000);
  }

  componentWillUnmount() {
    console.debug('WebChat.componentWillUnmount()');

    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    console.debug('WebChatComponent.render()');

    if (!directLine.botConnection) {
      console.log('WebChatComponent.render(): aborting, direct line not initialized yet');
      return null;
    }

    return (
      <>
          <img className="shadow-top" src="./assets/container-shadow.png" alt="shadow"></img>
          <div className="webchat-container">
            <div className="aside-container"></div>
            <div className="transcript-container">
              <ReactWebChat
                directLine={directLine.botConnection}
                locale='de-DE' // https://stackoverflow.com/a/57697620
                styleSet={this.state.styleSet}
                userID={directLine.userId}
                store={this.store}
                renderMarkdown={this.renderMarkdown}
              />
            </div>
            <div className="aside-container">
              <HelpComponent text={this.state.helpText} />
            </div>
          </div>
      </>
    );
  }
}
