export const environment = {
    serverUrl: parse(process.env.REACT_APP_SERVER_URL),
    groupIds: process.env.REACT_APP_GROUP_IDS
        ? process.env.REACT_APP_GROUP_IDS.split(',').map(group => group.trim())
        : []
};

function parse(value: string | undefined): string | null | undefined {
    if (value === 'undefined' || value === undefined) {
        return undefined;
    }
    if (value === 'null' || value === null) {
        return null;
    }
    return value;
}
