import React from "react";
import './Kumul.scss';
import { UpsertModal } from "./UpsertModal";
import Toggle from 'react-toggle';
import "react-toggle/style.css";
import { KumulEvent } from "../../models/KumulEvent";
import { backend } from "../../services/Backend";
import AzureAD, { AuthenticationState } from "react-aad-msal";
import { authProvider } from "../../auth/authProvider";
import { getUserGroups } from "../../auth/GraphService";
import { environment } from "../../environment";

export class KumulPage extends React.Component<{}, {
    events: KumulEvent[],
    isAuthorized: boolean
}> {

    private upsertModal: any;

    private authorizationInProgress: boolean = false;

    constructor(props: any) {
      super(props);
      console.debug('KumulPage.ctor()');
    
      this.upsertModal = React.createRef();

      this.state = { 
        events: [],
        isAuthorized: false
      };

      this.getKumulEvents();
    }

    async authorize() {
        console.debug('KumulPage.authorize()');
        if (this.authorizationInProgress) {
            return;
        }
        this.authorizationInProgress = true;
        try {
            const tokenResponse = await authProvider.getAccessToken();
            const userGroups = await getUserGroups(tokenResponse.accessToken);
            for (let groupId of environment.groupIds) {
                if (userGroups.value.find(group => group.id === groupId)) {
                    this.setState({ isAuthorized: true });
                    break;
                }
            }
        }
        catch (error) {
            console.error('KumulPage.authorize()', error);
        }
        finally {
            this.authorizationInProgress = false;
        }
    }

    getKumulEvents() {
        console.debug('KumulPage.getKumulEvents()');
        backend.getKumulEvents()
            .then((events: KumulEvent[]) => this.setState({ events }))
            .catch(error => console.error(error));
    }

    handleNew = (event) => {
        console.debug('KumulPage.handleNew()');
        this.upsertModal.current.open();
    };

    handleEdit = (name: string | null) => {
        console.debug(`KumulPage.handleEdit(${name})`);
        if (!name) {
            return;
        }
        const event = this.state.events.find(event => event.name === name);
        if (!event) {
            return;
        }
        this.upsertModal.current.open(event);
    };

    handleDelete = (name: string | null) => {
        console.debug(`KumulPage.handleDelete(${name})`);
        if (!name) {
            return;
        }
        backend.deleteKumulEvent(name)
            .then(() => this.getKumulEvents())
            .catch(error => console.error(error));
    };

    handleClose = (event: KumulEvent | null) => {
        console.debug(`KumulPage.handleClose()`, event);
        if (!event) {
            // cancelled
            return;
        }
        backend.upsertKumulEvent(event)
            .then(() => this.getKumulEvents())
            .catch(error => console.error(error));
    };

    handleActivated = (active: boolean, eventName: string | null) => {
        console.debug(`KumulPage.handleActivated()`, active, eventName);
        const event = this.state.events.find(e => e.name === eventName);
        if (!event) {
            return;
        }
        event.active = active;
        backend.upsertKumulEvent(event)
            .then(() => this.getKumulEvents())
            .catch(error => console.error(error));
    };

    render() {
        return (
            <>
                <div className="kumul-container">
                    <AzureAD provider={authProvider} forceLogin={true}>
                    {
                        ({login, logout, authenticationState, error, accountInfo}) => {
                            console.debug('KumulPage.AzureAD', authenticationState);
                            if (error) console.error('KumulPage.AzureAD', error);
                            
                            if (authenticationState === AuthenticationState.Authenticated) {
                                if (!this.state.isAuthorized) {
                                    this.authorize();
                                    return (<h1>Sie sind nicht autorisiert.</h1>);
                                }
                                return (
                                    <div className="table-container">
                                        <img className="shadow-top" src="./assets/container-shadow.png" alt="shadow"></img>
                                        <h1>Kumul Ereignisse</h1>
                                        <div className="table">
                                            <div className="table-row header">
                                                <div className="table-column name">Name</div>
                                                <div className="table-column remaining">Text</div>
                                                <div className="table-column state">Status</div>
                                                <div className="table-column edit">
                                                    <div onClick={this.handleNew} className="icon new">
                                                        <i className="fa fa-plus"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.events.map(event =>
                                            <div className="table-row">
                                                <div className="table-column name">{event.name}</div>
                                                <div className="table-column remaining">{event.welcomeMessage}</div>
                                                <div className="table-column state">
                                                    <Toggle checked={event.active} icons={false} className='state-toggle'
                                                        onChange={(e) => this.handleActivated(e.target.checked, event.name)} />
                                                </div>
                                                <div className="table-column edit">
                                                    <div onClick={() => this.handleEdit(event?.name)} className="icon">
                                                        <i className="fa fa-edit"></i>
                                                    </div>
                                                    <div onClick={() => this.handleDelete(event?.name)} className="icon delete">
                                                        <i className="fa fa-trash"></i>
                                                    </div>
                                                </div>
                                            </div>)}
                                        </div>
                                    </div>       
                                );
                            }
                            else {
                                return (<h1>Sie sind nicht authentifiziert.</h1>);
                            }
                        }
                    }
                    </AzureAD>
                </div>

                <UpsertModal ref={this.upsertModal}
                    onClose={this.handleClose} />
            </>
        );
    }

}
