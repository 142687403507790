import React from "react";
import './Help.scss';

export class HelpComponent extends React.Component<{
  text: string | null
}, {
    isTextVisible: boolean
}, any> {

    state = {
        isTextVisible: false
    };
  
    constructor(props: any) {
      super(props);
      console.debug('HelpComponent.ctor()');
    }

    handleClick = (_: any) => {
      console.debug('HelpComponent.handleClick()');
        
      this.setState({ isTextVisible: !this.state.isTextVisible });
    }
  
    render() {
      console.debug('HelpComponent.render()');

      if (!this.props.text) {
        return null;
      }

      return (
        <div className="help-container">
            <div className="help-icon">
                <button onClick={this.handleClick} type="button">
                    <img src="./assets/eye-icon.png" alt="Ausfüllhilfe"></img>
                </button>
            </div>
            <div className="help-header">Ausfüllhilfe</div>
            {this.state.isTextVisible && <div className="help-text custom-scrollbar margin-top-10">{this.props.text}</div>}
        </div>
      );
    }
}
